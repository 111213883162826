/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
#home .style-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
#home .home-container-fluid {
  position: relative;
  padding: 50px 8px;
}
#home .home-container {
  position: relative;
  padding: 50px 8vw;
}
@media screen and (max-width: 768px) {
  #home .home-container {
    padding: 50px 8px !important;
  }
}
#home .header {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  height: 75px;
}
#home .header .home-page {
  height: 100%;
  padding: 0 3vw;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
#home .header-logo {
  height: 60px;
}
#home .header-logo img {
  height: 100%;
}
#home .header-logo a {
  display: block;
}
#home .header-menu {
  height: fit-content;
  text-transform: uppercase;
  font-weight: 600 !important;
  line-height: 25px !important;
  border: none !important;
}
#home .header-menu .ant-menu-item-selected,
#home .header-menu .ant-menu-submenu-selected,
#home .header-menu .ant-menu-submenu:hover,
#home .header-menu .ant-menu-submenu-active,
#home .header-menu .ant-menu-item-active {
  color: #2eb55f !important;
  border-bottom: 2px solid #2eb55f !important;
}
#home .header-menu .ant-menu-submenu-title:hover {
  color: #2eb55f !important;
}
#home .header-menu .ant-menu-horizontal {
  border-bottom: none !important;
}
#home .header-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
  font-size: 14px;
}
#home .header-menu .ant-menu a {
  display: block;
}
#home .header-item-block {
  padding: 0 8px;
}
#home .header-item-block > * {
  display: inline-block;
}
#home .header-item .ant-menu-sub .ant-menu-item,
#home .header-item-child .ant-menu-sub .ant-menu-item,
#home .header-menu .ant-menu-sub .ant-menu-item,
#home .header-item .ant-menu-inline .ant-menu-item,
#home .header-item-child .ant-menu-inline .ant-menu-item,
#home .header-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
#home .header-item .item-sub-item,
#home .header-item-child .item-sub-item,
#home .header-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
#home .header-item .item-image,
#home .header-item-child .item-image,
#home .header-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
#home .header-item .item-title,
#home .header-item-child .item-title,
#home .header-menu .item-title {
  font-size: 14px;
  color: #666;
  margin-left: 46px;
}
#home .header-item .item-content,
#home .header-item-child .item-content,
#home .header-menu .item-content {
  font-size: 12px;
  color: rgba(102, 102, 102, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 768px) {
  #home .header {
    height: 56px;
  }
  #home .header-logo {
    z-index: 101;
    height: 80%;
  }
  #home .header .home-page {
    padding: 0 3vw;
  }
  #home .header-menu {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
    font-weight: 600 !important;
    line-height: 25px !important;
    border: none !important;
  }
  #home .header-menu .ant-menu-item-selected,
  #home .header-menu .ant-menu-submenu-selected,
  #home .header-menu .ant-menu-submenu:hover,
  #home .header-menu .ant-menu-submenu-active,
  #home .header-menu .ant-menu-item-active {
    color: #2eb55f !important;
    border-bottom: none !important;
  }
  #home .header-menu .ant-menu-sub .ant-menu-item {
    padding-left: 6vw !important;
  }
  #home .header-menu .ant-menu-item,
  #home .header-menu .ant-menu-submenu-title {
    padding-left: 4vw !important;
    height: 26px;
    line-height: 26px;
  }
  #home .header-menu .ant-menu-submenu-title:hover {
    color: #2eb55f !important;
  }
  #home .header-menu li.ant-menu-submenu {
    padding: 0;
  }
  #home .header-menu .item-sub-item {
    padding: 8px 0;
  }
  #home .header-mobile-menu {
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: 3vw;
    z-index: 100;
  }
  #home .header-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #2eb55f;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  #home .header-mobile-menu :first-child {
    margin-top: 0;
  }
  #home .header .ant-menu {
    height: auto;
    overflow: hidden;
  }
  #home .header .ant-menu .ant-menu-item-selected {
    border: none;
  }
  #home .header .open .header-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  #home .header .open .header-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  #home .header .open .header-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  #home .header .open > .header-menu {
    opacity: 1;
    pointer-events: auto;
  }
  #home .content-test {
    top: 56px !important;
  }
  #home .home-footer {
    top: 56px !important;
  }
}
#home .ant-layout {
  background-color: white !important;
}
#home .home-h2 {
  color: #2eb55f !important;
  font-size: 25px;
}
#home .currency-init {
  vertical-align: super;
}
#home .content-test {
  position: relative;
  top: 75px;
  min-height: 50vh;
}
#home .content-test h2 {
  margin-bottom: 1em;
}
#home .rightsider-menu {
  border: none;
  font-weight: 600 !important;
}
#home .rightsider-item {
  text-transform: none !important;
  border-bottom: 1px solid #aab4ac !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#home .menu-item.rightsider-item .anticon {
  font-size: 11px;
}
#home .breadcrumb-separator {
  color: white !important;
}
#home .breadcrumb-right > span:last-child {
  color: #fff !important;
}
#home .breadcrumb {
  background-image: linear-gradient(to right, #11853a, #5ab95b) !important;
  height: 75px;
  display: flex;
  justify-content: space-between;
  padding: 0px 4vw;
  align-items: center;
}
#home .breadcrumb-left {
  color: #fff !important;
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
  max-width: 55%;
}
#home .breadcrumb-right {
  display: inline-block;
  color: #fff !important;
  font-size: 15px;
}
#home .breadcrumb .ant-breadcrumb-separator {
  color: #fff !important;
}
@media screen and (max-width: 768px) {
  #home .breadcrumb {
    height: 70px !important;
  }
  #home .breadcrumb-left {
    display: none;
  }
  #home .breadcrumb-right {
    font-size: inherit;
  }
}
#home .salon-finder {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  height: 60vh;
}
#home .google-map-container {
  height: 60vh;
}
#home .salon-finder-list {
  overflow: auto;
  height: 60vh;
}
#home .home-footer {
  position: relative;
  background-color: #fff;
  border-top: 2px #e6e5e5 dotted;
  padding: 50px 8px;
  margin: 0px 4vw;
  top: 75px;
}
#home .home-footer ul {
  list-style: none;
  padding: 0px;
}
#home .home-footer li {
  margin: 10px 0px;
}
#home .footer-products {
  display: flex;
  flex-wrap: wrap;
}
#home .footer-products-item {
  margin: 2px !important;
  text-transform: uppercase;
  overflow: hidden;
}
#home .footer-products-item span {
  color: #2eb55f !important;
  border-color: #2eb55f !important;
  max-width: 150px;
  overflow: hidden;
}
#home .footer-products-item:hover span {
  cursor: pointer;
}
#home .footer-logo {
  width: 120px !important;
  margin: 13px 0px;
}
#home .footer-logo img {
  width: 100%;
}
#home .footer-title {
  font-size: large;
}
#home .post-detail h3 {
  color: #2eb55f !important;
  font-size: 20px;
}
#home .post-detail .post-quote {
  color: #2eb55f;
  border-left: 3px #2eb55f solid;
  padding: 10px 20px;
}
#home .ant-card-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home .postCard-col {
  flex-basis: 30%;
  width: 30%;
  position: relative;
  padding-top: 45% !important;
  margin: 8px;
}
#home .postCard-col .card-vertical {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none !important;
}
#home .postCard-col .card-vertical .ant-card-cover {
  width: 100%;
  height: 45%;
}
#home .postCard-col .card-vertical .ant-card-body {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55%;
  transition: 0.3s;
  text-align: center !important;
  padding: 16px 8px 0px 8px;
}
#home .postCard-col .card-vertical .ant-card-body .ant-card-meta-description :first-child {
  color: #2eb55f !important;
  text-transform: uppercase;
}
#home .card-horizonal {
  display: flex;
  height: 160px;
  overflow: hidden;
  margin: 16px 0px;
}
#home .card-horizonal .ant-card-cover {
  width: 30%;
}
#home .card-horizonal .ant-card-body {
  width: 70%;
}
#home .card-horizonal .ant-card-body .ant-card-meta-description :first-child {
  color: #2eb55f !important;
  text-transform: uppercase;
}
@media screen and (max-width: 414px) {
  #home .postCard-col {
    margin: 4px;
  }
  #home .postCard-col .card-vertical .ant-card-body {
    padding: 8px 0px 0px;
  }
  #home .postCard-col .card-vertical .ant-card-body .ant-card-meta-description :last-child {
    white-space: nowrap;
  }
  #home .card-horizonal .ant-card-cover {
    width: 40%;
  }
  #home .card-horizonal .ant-card-body {
    width: 60%;
    padding: 16px;
  }
}
@media screen and (min-width: 1025px) {
  #home .postCard-col {
    padding-top: 40% !important;
  }
  #home .postCard-col .ant-card-cover {
    height: 50% !important;
  }
  #home .postCard-col .ant-card-body {
    height: 50% !important;
  }
}
#home .productCard {
  border: none !important;
}
#home .productCard .ant-card-cover {
  position: relative;
  width: 100%;
  padding-top: 100%;
}
#home .productCard .ant-card-cover img {
  position: absolute;
  top: 0;
}
#home .productCard .ant-card-body {
  text-align: center !important;
}
#home .productCard .ant-card-body h4 {
  font-size: 15px;
}
#home .productCard.ant-card-hoverable:hover {
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15) !important;
}
@media screen and (max-width: 414px) {
  #home .ant-card-body h4 {
    font-size: inherit;
  }
}
@media screen and (max-width: 414px) {
  #home .productCard2-col {
    flex-basis: 50% !important;
    width: 100% !important;
    padding-top: 75% !important;
  }
  #home .productCard2-col:last-child {
    display: none;
  }
}
#home .productCard2-col {
  flex-basis: 20%;
  width: 100%;
  position: relative;
  padding-top: 30%;
}
#home .productCard2-col .productCard2 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#home .productCard2-col .productCard2 .ant-card-cover {
  width: 100%;
  height: 100%;
}
#home .productCard2-col .productCard2 .ant-card-body {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 14%;
  padding-top: 100%;
  background: rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}
#home .productCard2-col .productCard2 .ant-card-body .ant-typography {
  color: white;
}
#home .productCard2-col .productCard2 .ant-card-body:hover {
  background: rgba(0, 0, 0, 0.2);
}
#home .product-detail .properties-name {
  font-weight: bold;
  text-transform: capitalize;
}
#home .product-detail-img {
  position: relative;
  width: 30vw;
  padding-top: 30vw;
  background-color: pink;
}
#home .product-detail .carousel-status {
  display: none;
}
#home .product-detail .padding-carousel-col3 {
  padding-top: 100px !important;
}
#home .product-detail .padding-carousel-col2 {
  padding-top: 8px !important;
}
@media screen and (max-width: 414px) {
  #home .product-detail-img {
    width: 96vw;
    padding-top: 96vw;
  }
  #home .product-detail .padding-carousel-col2 {
    padding-top: 100px !important;
  }
  #home .product-detail .padding-carousel-col3 {
    padding-top: 8px !important;
  }
}
#home .video-wrapper {
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  padding-top: 25;
  height: 0;
  margin: 25px 0px;
}
#home .video-wrapper .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
#home .banner-fullwidth {
  width: 100%;
  position: relative;
  padding-top: 42%;
}
#home .banner-right {
  width: 100%;
  margin: 16px 0px;
  position: relative;
  padding-top: 133%;
}
#home .vertical-center-banner {
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
#home .vertical-center-banner .carousel.carousel-slider,
#home .vertical-center-banner .carousel .slider-wrapper,
#home .vertical-center-banner .carousel .slider {
  height: 100% !important;
}
#home .vertical-center-banner .carousel .slide img {
  height: 100% !important;
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  #home .banner-right {
    display: none;
  }
}
#home .ggmap-wrapper {
  position: relative !important;
  height: 100vh !important;
  width: 100vw !important;
}
#home .contact {
  text-align: center;
}
#home .contact iframe {
  height: 450px !important;
  width: 100% !important;
}
#home .contact-icon {
  font-size: 25px;
  color: #2eb55f;
  margin-bottom: 5%;
}
#home .contact h1 {
  font-size: xx-large;
}
#home .title-green {
  color: #2eb55f !important;
  text-transform: uppercase;
  text-align: left;
}
#home .product-detail .title-divide {
  text-align: center;
}
#home .product-detail .ant-divider-horizontal {
  min-width: 100px;
  width: 100px;
  height: 2px;
  margin: auto;
  margin-bottom: 24px;
  background: #2eb55f;
}
#home .product-detail .title-green {
  font-weight: 400;
}
#home .post-detail h2 {
  color: #2eb55f !important;
  text-transform: uppercase;
  text-align: left;
}
#home .rightsider.title-green {
  font-size: 18px !important;
}
#home .home-index .title-divide {
  text-align: center !important;
  font-weight: 400;
}
#home .home-index-content {
  text-align: center;
}
#home .home-index-content img {
  max-width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  #home .content-test h2 {
    font-size: large !important;
  }
  #home p {
    font-size: inherit !important;
  }
}
#home figure {
  margin: 1em auto !important;
  text-align: center !important;
}
#home figure img {
  width: 100%;
  max-width: 100%;
}
#home figure figcaption {
  text-align: center !important;
}
