#login {
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
}
#login .content {
  max-width: 500px;
  padding: 20px;
  margin: 20px auto;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
#login .logo {
  margin: 10px 0px 30px;
  text-align: center;
}
#login .logo .img {
  display: block;
}
#login .logo .img img {
  max-height: 70px;
}
#login .logo .sitename {
  display: block;
  position: relative;
  top: 5px;
  padding: 0px 20px;
  font-size: 30px;
}
#login .btnLogin {
  width: 100%;
}
